<template>
  <div class="main">
    <div class="main_box">
      <h2 class="main_title">{{detailsData.name || '-'}}</h2>
      <div class="main_vice">
        <p>{{detailsData.specs}}</p>
      </div>
      <div class="flex_center main_tag">
        <div class="flex_center main_tag_l">
          <div class="main_tag_l_item">{{detailsData.county || detailsData.city || detailsData.province || "-"}}</div>
          <div class="main_tag_l_item">{{detailsData.publishDate||"-"}}</div>
        </div>
        <div class="main_tag_r"></div>
      </div>
      <div class="main_table">
        <el-table
          :data="tableData"
          border
          style="width: 100%">
          <el-table-column
            prop="showDate"
            label="时间"
            width="180">
          </el-table-column>
          <el-table-column
            prop="minPrice"
            label="除税价(元)"
            width="180">
          </el-table-column>
          <el-table-column
            prop="minTaxPrice"
            label="含税价(元)">
          </el-table-column>
          <el-table-column
            prop="unit"
            label="单位">
          </el-table-column>
          <el-table-column
            prop="tongbi"
            label="同比">
          </el-table-column>
          <el-table-column
            prop="huanbi"
            label="环比">
          </el-table-column>
        </el-table>
      </div>
      <div class="main_chart">
        <h3 class="main_chart_title">近三月价格同比、环比走势图</h3>
        <div ref="chart" style="width: 100%; height: 456px;"></div>
      </div>
      <div class="main_chart">
        <h3 class="main_chart_title">近三月价格走势图</h3>
        <div ref="chart1" style="width: 100%; height: 456px;"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import { urlProjectPriceInfoEsDetails, urlProjectPriceInfoEsRecently } from '@/api/engineering'
  import * as echarts from "echarts";
  export default {
    name: "infoPriceDetails",
    data() {
      return {
        option: {
          color: ['#007EF2', '#36CFC9'],
          tooltip: {
            trigger: 'none',
            axisPointer: {
              type: 'cross'
            }
          },
          legend: {},
          grid: {
            top: 70,
            bottom: 50
          },
          xAxis: {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: '#4E4E4E'
              }
            },
            axisPointer: {
              label: {
                formatter: function (params) {
                  return (
                    params.value +
                    (params.seriesData.length ? '：' + params.seriesData[0].data : '')
                  );
                }
              }
            },
            data: []
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name: '同比',
              type: 'line',
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              data: []
            },
            {
              name: '环比',
              type: 'line',
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              data: []
            }
          ]
        },
        option1: {
          color: ['#007EF2', '#36CFC9'],
          tooltip: {
            trigger: 'none',
            axisPointer: {
              type: 'cross'
            }
          },
          legend: {},
          grid: {
            top: 70,
            bottom: 50
          },
          xAxis: {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: '#4E4E4E'
              }
            },
            axisPointer: {
              label: {
                formatter: function (params) {
                  return (
                    params.value +
                    (params.seriesData.length ? '：' + params.seriesData[0].data : '')
                  );
                }
              }
            },
            data: []
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name: '除税价',
              type: 'line',
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              data: []
            },
            {
              name: '含税价',
              type: 'line',
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              data: []
            }
          ]
        },
        tableData: [],
        detailsData: {}
      }
    },
    async mounted() {
      this.getChart = echarts.init(this.$refs.chart)
      this.getChart1 = echarts.init(this.$refs.chart1)
      await this.getProjectPriceInfoEsDetails()
      await this.getProjectPriceInfoEsRecently()
    },
    methods: {
      async getProjectPriceInfoEsDetails() {
        const { id } = this.$route.query
        const param = {
          id: id
        }
        const res = await urlProjectPriceInfoEsDetails(param)
        this.detailsData = res.data
        this.uuid = res.data.uuid
      },
      async getProjectPriceInfoEsRecently() {
        const { uuid } = this.$route.query
        const param = {
          uuid: uuid || this.uuid
        }
        const res = await urlProjectPriceInfoEsRecently(param)
        let records = res.data.slice(0, 3)
        let xAxis = []
        let seriesL = []
        let seriesR = []
        let minPrice = []
        let minTaxPrice = []
        records.forEach(item => {
          item.showDate = (item.year + '年') + (item.mouthOrQuarter || "")
          xAxis.push(item.showDate)
          seriesL.push(item.tongbi || 0)
          seriesR.push(item.huanbi || 0)
          minPrice.push(item.minPrice || 0)
          minPrice.push(item.minTaxPrice || 0)
        })
        this.tableData = records
        this.option.xAxis.data = xAxis
        this.option.series[0].data = seriesL
        this.option.series[1].data = seriesR
        this.option1.xAxis.data = xAxis
        this.option1.series[0].data = minPrice
        this.option1.series[1].data = minTaxPrice
        this.getChart.setOption(this.option)
        this.getChart1.setOption(this.option1)
      }
    }
  }
</script>

<style scoped lang="less">
  .main {
    text-align: left;
    .main_box {
      .main_title {
        font-size: 20px;
        font-weight: bold;
        color: #212121;
        padding-bottom: 20px;
      }
      .main_vice {
        font-size: 20px;
        color: #212121;
        padding-bottom: 20px;
      }
      .main_tag {
        justify-content: space-between;
        padding-bottom: 20px;
        .main_tag_l {
          margin: 0 -6px;
          .main_tag_l_item {
            height: 28px;
            margin: 0 6px;
            padding: 0 6px;
            background-color: #F6F7FB;
            border-radius: 4px;
            font-size: 16px;
            color: #4E4E4E;
            line-height: 28px;
          }
        }
        .main_tag_r {
          font-size: 14px;
          color: #4E4E4E;
        }
      }
      .main_table {
        padding-bottom: 20px;
      }
      .main_chart {
        background-color: #fff;
        box-shadow: 3px 3px 16px 1px rgba(0,0,0,0.08);
        .main_chart_title {
          padding: 40px 0 20px;
          font-size: 32px;
          font-weight: bold;
          color: #000;
          text-align: center;
        }
      }
    }
  }
</style>